var $ = jQuery.noConflict();


// Start gForm Transition Effect
var gf_active_page = 0; 
$('.gform_page').addClass('active');

function setPage(current_page, form_id) {
	setTimeout(function() {
	    $('.gform_wrapper .gform_page').removeClass('active');
	    $('.gform_wrapper #gform_page_'+form_id+'_'+current_page).addClass('animated fadeInUp active').delay(1000).addClass('active');
	    gf_active_page = current_page;
	}, 100);
}

$(document).bind('gform_page_loaded', function(event, form_id, current_page) {

	if(gf_active_page != current_page) { 
		setPage(current_page, form_id);
    } else {
        $('.gform_wrapper #gform_page_'+form_id+'_'+current_page).addClass('active');
    }
    
});
// END // gForm Transition Effect

$(window).resize(function(e){
    if(window.innerWidth < 576) {
		mobSlider();
	}
});

function mobSlider() {
	$('#leadership-listing').slick({
		arrows: false,
		autoplay: false,
		speed: 1000, 
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplaySpeed: 5000,
		centerMode: true,		
		mobileFirst: true
	});
	$('#board_membe').slick({
		arrows: false,
		autoplay: false,
		speed: 1000,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplaySpeed: 5000,
		centerMode: true,		
		mobileFirst: true
	});	
	$('.why-3 #slider-benefits').slick({
		arrows: false,
		autoplay: false,
		speed: 1000,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplaySpeed: 5000,
		centerMode: true,		
		mobileFirst: true
	});		
}


function pLoadFormValidation(){
	$('.getintouch_form input').on('keyup', function(){
	if($(this).val().length >=3){
	$(this).parent().parent().addClass('validated');
	}else{
	$(this).parent().parent().removeClass('validated');
	}
	});

	function isEmail(email) {
	var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
	return regex.test(email);
	}

	$('.getintouch_form .email_input input').on('keyup', function(){
	if(isEmail($(this).val())){
	$(this).parent().parent().addClass('validated');
	}else{
	$(this).parent().parent().removeClass('validated');
	}
	});

	$('.getintouch_form textarea').on('keyup', function(){
	if($(this).val().length >=25){
	$(this).parent().parent().addClass('validated');
	}else{
	$(this).parent().parent().removeClass('validated');
	}
	});
}

$(window).on('load', function(){
	pLoadFormValidation();
});

$(document).ajaxComplete(function(){
	pLoadFormValidation();
});



$(document).ready(function($) {



	// Request demo
	$(document).on('gform_confirmation_loaded', function(event, formId){
		if(formId == 9){
			window.scrollTo({top: 0, behavior: 'smooth'});
			$('.requestDemo_rightbg_image').show();
			$('.requestDemo_rightbg_text').hide();
			$("form").each(function(index){
			$(this).find(':input').val('');
			});
			$("input").attr("disabled", true); $( ":button" ).val( "Submitted" );
		}
	});	






var articleButton = $('#view_press_list');
var ajaxurls = ajax_object.ajax_url;

// articleButton.on('click', function(e){
// e.preventDefault();
// var page = articleButton.data( 'page' );
// var newPage = parseInt(page)+1;
// articleButton.data('page', newPage);
// $.ajax({
// type: 'POST',
// url: ajaxurls,
// dataType: "json",
// data: {
// action: "load-filter2",
// page: newPage,
// },
// success: function (response) {
// if(response.articles != '')
// {
// $('.article-post-block').append(response.articles);
// }
// if(response.found_posts < 2 || articleButton.data('total-post') <= $('.article-post-block').children().length)
// {
// $('#view_press_list').hide();
// }
// },
// error: function () {
// alert('Something went wrong, please try again later');
// }
// });
// });

var latestNewsButton = $('#view_latest_news');
latestNewsButton.on('click', function(e) {
    e.preventDefault();
    var page = latestNewsButton.data('page');
    var newPage = parseInt(page) + 1;
    latestNewsButton.data('page', newPage);
    $.ajax({
        type: 'POST',
        url: ajaxurls,
        dataType: "json",
        data: {
            action: "load-latest-news",
            page: newPage,
        },
        success: function(response) {
            if (response.latestnews != '') {
                $('#article_blocks').append(response.latestnews);
				
            }
            if (latestNewsButton.data('total-post') - 6 == $('#article_blocks').children().length) {
			// if ( posts displayed = total posts){
                $('#view_latest_news').hide();
				
            }
			// console.log(latestNewsButton.data('total-post'));
			// console.log( $('#article_blocks').children().length );
        },
        error: function() {
            alert('Something went wrong, please try again later');
        }
    });
});

















	// Rotate Tab Script
	function rotateTabs(blnClicked, tabId){
		var nextTabId;
		if(blnClicked){
		nextTabId = tabId;
		}else{
			startSetInterval
			var tabDataPlatform = $('.platform path.tab');
			var totalTabs = tabDataPlatform.length;
			var activeTabId = $('.platform .tab.active').attr('tab-id');
			
			if(undefined !== activeTabId && activeTabId.length)
			{
				var intActiveTabId = parseInt(activeTabId.substr(3,activeTabId.length ));
				if((undefined!==totalTabs) &&  (intActiveTabId == totalTabs)){				
					nextTabId = 1;
				}else{				
					nextTabId = intActiveTabId + 1;
				}	
			}		
		}
		$('.platform .tab.active').toggleClass('active');
		$('.platform').find("[tab-id='tab" + nextTabId + "']").toggleClass('active');
		$('.platform .inner_platform.active').toggleClass('active');
		$('.platform').find("[tab-content='tabContent" + nextTabId + "']").toggleClass('active');
	}

	var timer = null;
	function startSetInterval() {
		timer = setInterval(rotateTabs,5000);
	}

	startSetInterval();

	$('.platform svg .tab').hover(function() {
		var tabId= $(this).attr('tab-id');
	
		if(undefined !== tabId && tabId.length)
		{	 
			var intClickedTabId = parseInt(tabId.substr(3,tabId.length ));
			rotateTabs(true, intClickedTabId);
			clearInterval(timer);
		}
	},function() {
		startSetInterval();
	});
	// END /- Rotate Tab Script
	

	if(window.innerWidth < 576) {
		mobSlider();
	}

	$('#slider-clients').slick({
		arrows: false,
		autoplay: false,
		speed: 1000,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplaySpeed: 5000		
	});	
	

	$('#resources-slider').slick({
		arrows: true,
		autoplay: false,
		speed: 1000,
		slidesToShow: 1,
		dots: true,
		slidesToScroll: 1,
		//centerMode: true,
		autoplaySpeed: 5000		
	});	

	$('#press_slider').slick({
		arrows: true,
		autoplay: false,
		speed: 1000,
		slidesToShow: 1,
		dots: false,
		slidesToScroll: 1,
		//centerMode: true,
		autoplaySpeed: 5000		
	});		


	// No Javscript Fallback
	$('body').removeClass('no-js');


	// if adminbar exist (should check for visible?) then add margin to our navbar
	var $wpAdminBar = $("#wpadminbar");
	if ($wpAdminBar.length > 0) {
		$(".logged-in .main_header").css("margin-top", $wpAdminBar.height() + "px");
	}

	// Replace all SVG images with inline SVG
	$(function() {
		$("img.svg").each(function() {
			var $img = $(this);
			var imgID = $img.attr("id");
			var imgClass = $img.attr("class");
			var imgURL = $img.attr("src");
			$.get(
				imgURL,
				function(data) {
					// Get the SVG tag, ignore the rest
					var $svg = $(data).find("svg");
					// Add replaced image's ID to the new SVG
					if (typeof imgID !== "undefined") {
						$svg = $svg.attr("id", imgID);
					}
					// Add replaced image's classes to the new SVG
					if (typeof imgClass !== "undefined") {
						$svg = $svg.attr("class", imgClass + " replaced-svg");
					}
					// Remove any invalid XML tags as per http://validator.w3.org
					$svg = $svg.removeAttr("xmlns:a");
					// Replace image with new SVG
					$img.replaceWith($svg);
				},
				"xml"
			);
		});
	});

	// Toggle Navbar scroll
	var scroll = $(window).scrollTop();
	if (scroll > 0 || scroll !== 0) {
		$("body").addClass("scrolled");
		$("body").removeClass("not-scrolled");
	} else {
		$("body").removeClass("scrolled");
		$("body").addClass("not-scrolled");
	}

	$(window).scroll(function() {
		var scroll = $(window).scrollTop();
		if (scroll > 0 || scroll !== 0) {
			$("body").addClass("scrolled");
			$("body").removeClass("not-scrolled");
		} else {
			$("body").removeClass("scrolled");
			$("body").addClass("not-scrolled");
		}
	});

	// Cookie Banner
	if (localStorage.getItem("concoursecookieSeen") != "shown") {
		$("#cookie-banner").delay(2000).fadeIn();
		$( "body" ).addClass( "cookie-banner-showing" );
	};
	$("#close-cookie-banner").click(function() {
		$("#cookie-banner").fadeOut();
		localStorage.setItem("concoursecookieSeen","shown");
	});


	// Smooth Scroll
	// Select all links with hashes
	$('a[href*="#"]')
	// Remove links that don't actually link to anything
	.not('[href="#"]')
	.not('[href="#0"]')
	.not('[href*=nav]')
	.click(function(event) {
		// On-page links
		if (
		location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') 
		&& 
		location.hostname == this.hostname
		) {
			// Figure out element to scroll to
			var target = $(this.hash);
			target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
			// Does a scroll target exist?
			if (target.length) {
				// Only prevent default if animation is actually gonna happen
				event.preventDefault();
				$('html, body').animate({
					scrollTop: target.offset().top
				}, 1000, function() {
					// Callback after animation
					// Must change focus!
					var $target = $(target);
					$target.focus();
					if ($target.is(":focus")) { // Checking if the target was focused
						return false;
					} else {
						$target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
						$target.focus(); // Set focus again
					};
				});
			}
		}
	});

	
	

	// Initiate Animate on Scroll
	AOS.init();

	$(".ring-nav-pink").click(function() {
    $('html,body').animate({
        scrollTop: $("#personalized-benefits-hub").offset().top},
        'slow');
	});

	$(".ring-nav-green").click(function() {
    $('html,body').animate({
        scrollTop: $("#proactive-coaching").offset().top},
        'slow');
	});

	$(".ring-nav-blue").click(function() {
    $('html,body').animate({
        scrollTop: $("#clinician-designed-programs").offset().top},
        'slow');
	});


	// CHARTS.JS - For Employers Page
	/*
	var ctx = $('#myPieChart');
	var myPieChart  = new Chart(ctx, {
		type: 'doughnut',
		data: {
			datasets: [{
				data: [67,33],
				backgroundColor: [
					'rgba(154, 219, 232, 1)',
					'rgba(246, 246, 245, 1)'
				],

			}]
		},
		options: {
			cutoutPercentage: 80,
			responsive: true,
			showTooltips: false
		}
	
	});
	*/

	gsap.utils.toArray(".gradient-bg").forEach(function(elem) {

	  var color = elem.getAttribute('data-color');
	  
	  ScrollTrigger.create({
	    trigger: elem,
	    start:'top 50%',
	    end:'bottom 50%',
	    onEnter: () => gsap.to('#benefits-section', {backgroundImage:"-webkit-linear-gradient(118deg," + color + " 44%, white)"}),
	    onLeave: () => gsap.to('#benefits-section', {backgroundImage:"-webkit-linear-gradient(118deg," + color + " 44%, white)"}), 
	    onLeaveBack: () => gsap.to('#benefits-section', {backgroundImage:"-webkit-linear-gradient(118deg," + color + " 44%, white)"}), 
	    onEnterBack: () => gsap.to('#benefits-section', {backgroundImage:"-webkit-linear-gradient(118deg," + color + " 44%, white)"}), 
	    // markers:true
	  });

	});
	
	enableMobileSlider($('.mobile-slider'));

	/*
	var employersGradientHeight;
    var s1 = $('#personalized-benefits-hub').outerHeight();
	var s2 = $('#clinician-designed-programs').outerHeight();
	var s3 = $('#proactive-coaching').outerHeight();
	var s4 = $('#stats-section').outerHeight();
	var halfs4 = s4/2;
	*/
	
});

// Slick interface on mobile for repeating content
function enableMobileSlider(selector) {
	selector.slick({
		infinite: false,
		slidesToShow: 1,
		slidesToScroll: 1,
		centerMode: true,
  		centerPadding: '20px',
		mobileFirst: true,
		dots: true,
		prevArrow: false,
		nextArrow: false,
		adaptiveHeight: true,
		responsive:[{
			breakpoint: 768,
			settings: 'unslick'
		}]
	});
}

$(window).on('resize', function() {
	if ($(window).outerWidth(true) < 768) {
		if ($('.mobile-slider').not('.slick-initialized').length > 0) {
			enableMobileSlider($('.mobile-slider'));
		}
	}
});
