$(function () {

  $(window).scroll(function () {
    if ($(window).width() > 640) {
      var scrollTop = $(window).scrollTop();
      var imgPos = scrollTop / 4 + 'px';
      $('.pacifier').css('transform', 'translate(calc(' + imgPos + '*0.5), ' + imgPos + ')');
      $('.keyboard-cactus').css('transform', 'translate(calc(-' + imgPos + '*0.25), ' + imgPos + ')');
    }
  });


    /* We do this because android and chrome didnt like the slick "responsive" treatment :( */
      // if (window.screen.width > 640) {
    $('#slider-consultant').slick({
      autoplay: true,
      autoplaySpeed: 3000, 
      speed: 3000,
      slidesToShow: 3,
      infinite: true,
      slidesToScroll: 3,
      easing: "easeOutSine",
      arrows: false,
      responsive: [
        {
          breakpoint: 640,
          settings: {
            slidesToShow: 1
          }
        }
      ]
    });
  // } else {
  //   $('#slider-consultant').slick({
  //     autoplay: true,
  //     autoplaySpeed: 3000,
  //     speed: 1000,
  //     slidesToShow: 1,
  //     infinite: true,
  //     slidesToScroll: 1,
  //     easing: "easeOutSine",
  //     arrows: false
  //   });
  // }


  $('#slider-testimonial').slick({
    autoplaySpeed: 5000,
    speed: 1000,
    autoplay: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    easing: "easeOutSine",
    dots: true,
  });

  $('#slider-testimonial2').slick({
    autoplaySpeed: 5000,
    speed: 1000,
    autoplay: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    easing: "easeOutSine",
    adaptiveHeight: true,
    arrows: true,
    dots: true
  });


  if (window.screen.width < 640) {
    $('#slider-benefits').slick({
      autoplay: true,
      autoplaySpeed: 5000,
      speed: 1000,
      easing: "easeOutSine",
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,
      dots: true
    });
  }

  if (window.screen.width < 640) {
    $('#slider-percentages').slick({
      autoplay: true,
      autoplaySpeed: 2000,
      speed: 1000,
      easing: "easeOutSine",
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true
    });
  }

  // $('#slider-guidance').slick({
  //   autoplay: true,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   dots: true,
  // });

  // $('#slider-guidance').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
  //   if (nextSlide == 0) {
  //     $('#rotator').attr('class', 'rotate-1');
  //   } else if (nextSlide == 1) {
  //     $('#rotator').attr('class', 'rotate-2');
  //   } else if (nextSlide == 2) {
  //     $('#rotator').attr('class', 'rotate-3');
  //   }
  // });


  if (window.screen.width > 768) {
    $('#slider-solutions').slick({
      autoplaySpeed: 2000,
      easing: "easeOutSine",
      autoplay: true,
      infinite: true,
      centerMode: true,
      centerPadding: '0',
      arrows: false,
      slidesToShow: 3,
      slidesToScroll: 1
    });
  } else {
    $('#slider-solutions').slick({
      autoplaySpeed: 2000,
      easing: "easeOutSine",
      autoplay: true,
      infinite: true,
      centerMode: true,
      centerPadding: '0',
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1
    });
  }


  if (window.screen.width < 640) {
    $('#slider-clients').slick({
      autoplay: true,
      easing: "easeOutSine",
      autoplaySpeed: 5000,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,
      dots: true
    });
  }
  if (window.screen.width < 640) {
    $('#slider-covid19-videos').slick({
      autoplay: true,
      easing: "easeOutSine",
      autoplaySpeed: 5000,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,
      dots: true
    });
  }

  if (window.screen.width < 640) {
    $('#slider-career-benefits').slick({
      autoplay: true,
      autoplaySpeed: 2000,
      speed: 1000,
      easing: "easeOutSine",
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,
      dots: true
    });
  }

  $('.careers-2 .img').slick({
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 2000,
    easing: "easeOutSine",
    fade: true,
    arrows: false,
    infinite: true
  });

  if (window.screen.width < 640) {
    $('#slider-clients-platform').slick({
      // autoplay: true,
      easing: "easeOutSine",
      // autoplaySpeed: 5000,
      // speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,
      dots: true,
      arrows: false
    });
  }

$('#client-feature').slick ({
  autoplay: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  infinite: true,
  dots: true,
  arrows: true,
  asNavFor: '#app-feature',
  // appendArrows: '.page-template-client .container'
});
$('#app-feature').slick ({
  autoplay: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  infinite: true,
  dots: false,
  arrows: false,
  asNavFor: '#client-feature',
  fade: true,
  // appendArrows: '.page-template-client .container'
});

$('#employers-testimonials').slick ({
  slidesToShow: 1,
  slidesToScroll: 1,
  infinite: true,
  dots: true,
  arrows: true
});



  $('#menu-trigger').on('click', function () {
    $('body').toggleClass('menu-active');
  });

  $('.parent').on('mouseenter', function () {
    $('.dropdown-active').removeClass('dropdown-active');
    $(this).parent().toggleClass('dropdown-active');
  });

  $('header nav>ul>li>a:not(.parent)').on('mouseenter', function () {
    $('.dropdown-active').removeClass('dropdown-active');
    $(this).parent().removeClass('dropdown-active');
  });

  $('.parent+ul').on('mouseleave', function () {
    $('.dropdown-active').removeClass('dropdown-active');
  });

  
  $('.date').each(function () {
    var mGMT = moment($(this).text()); 
    var mPST = mGMT.add(7, 'hour');;
    // should fix this in the metalsmith build instead with a plugin 
    $(this).text(mPST.format('MMMM D, YYYY'));
  });
  

  $('.careers-5 .read-more').on('click', function() {
    var scrollToBio = $(this).attr('href');
    $(this).prev('.extended-text').toggleClass('open');
    if ($(this).text()=='Close') {
      $(this).removeClass('close').text('Read More');
      return true;
    } else {
      $(this).addClass('close').text('Close');
      return false;
    }
  });

  $(document).on("click", ".careers-5 a.close" , function() {
    $(this).prev('.extended-text').css('height', '16.5rem');
  });

  $('.faq .q').on('click', function(){
    if ($(this).hasClass('expanded')) {
      $(this).toggleClass('expanded');
    } else {
      $('.faq .q').removeClass('expanded');
      $(this).addClass('expanded');
    }
  }) 

  /*var selectedPerson = window.location.pathname;
  var nextPerson = '';
  var prevPerson = '';
  var totalPeople = (people.length - 1);

  $(people).each(function (id) {

    if (selectedPerson == this) {

      if (id == 0) {
        prevPerson = people[parseInt(totalPeople)];
      } else {
        prevPerson = people[parseInt(id - 1)];
      }

      if (id == totalPeople) {
        nextPerson = people[parseInt(0)];
        console.log("LAST")
      } else {
        nextPerson = people[(id + 1)];
      }

    }

  })


  $('body.person main .post-wrapper').append('<div class="people-pager"></div>')
  $('body.person main .people-pager').append('<a href="' + prevPerson + '" class="h6 arrow-prev">Previous</a>')
  $('body.person main .people-pager').append('<a href="' + nextPerson + '" class="h6 arrow-next">Next</a>')*/

  

});

