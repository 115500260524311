var $ = jQuery.noConflict();


$(document).ready(function(){
	    resource_filter_posts(); 
	});
	/*Resource List Posts*///  search_tearm
	function resource_filter_posts (cat_ids, paged){
		$('.resource_category_sec .loadmore').hide();
		var blnloadData = false;
		var pagination = $('#pagination').val();
		if(!cat_ids) cat_ids = $('#nav-tab a.nav-link.active').data('catid');
		if($('#searchresources').val() != ''){
			var s_trems = $('#searchresources').val();
			$('#loadmorecontent').html('');	
			$('#pagination').val(parseInt(1));
			$('#no-more').hide();
			$('.loadmore').show();
		}else{
			var dd = parseInt(pagination);
			$('#pagination').val(parseInt(1));
			$('#loadmorecontent').html('');	
			$('#no-more').html('');
			$('.loadmore').show(); 
			var s_trems = '';
		}
	    var data = {
	        'action': 'resource_filter_posts',
	        'cat_ids': cat_ids,
	        'ppp': paged,
			'keyword': s_trems       
	    };
	    $.ajax({
	        type: "POST",
	        url: ajax_object.ajax_url,
	        data: data,
	        dataType: 'json',
	        beforeSend: function (){      
	        	$('.resource_category_sec .loadmore').show();
	        	$('.card-row').hide();     
	            $('.filter-loading').fadeIn();
	            $('.resource-load').hide();
	            },
	        success: function(response){
				blnloadData = false;
	            if (response) {
	            	//$('.card-row').html( 'No posts found.' );
					$('.card-row').hide();
					if(response.frist_append){
						$('.resource_category_sec .loadmore').hide();
	                	$('#card-row-html .first-load').remove();
						$('#card-row-html').prepend(response.resourcelist);
						if(response.totalpost <= 6){
							$('#pagination').val(0);
							$('.loadmore').hide();
							$('#no-more	').hide();
						}
					}	   
					if(response.frist_post_id) $('#post_load_ids').val(response.frist_post_id);
					$('.card-row').show();
					if(data.resourcelist == ""){
						$('#pagination').val(0);
						$('.loadmore').hide();
						$('#no-more').show();
					}
	            }
	        },
	    });
	};
	/* Ajax functions */
	$(document).ready(function($) {
		//find scroll position
		var blnloadData = false;
		$(window).scroll(function() {	
			
			//init
			footerDistance = $('footer').offset().top;
			cat_ids = $('#nav-tab a.nav-link.active').data('catid');
			var that = $('#loadMore');
			var page = $('.loadmore').data('page');
			var pagination = $('#pagination').val();
			var searchresources = $('#searchresources').val();
			var newPage = page + 1;
				if(!blnloadData){
					
					blnloadData = true;
					if(window.scrollY  > $('footer').offset().top - $(window).height()){
						//your code here
						var id = $('.nav-item>.active').data('catid');
						//your code here
						if(pagination != 0){
							$.ajax({
								url: ajax_object.ajax_url,
								type: 'post',
								dataType: 'json',
								data: {
									page: pagination,
									action  : 'resource_filter_posts',
									keyword : searchresources,
									loadids : $('#post_load_ids').val(),
									cat_ids : cat_ids,
								},
								error: function(response) {
									console.log(response);
								},
								success: function(data) {
									//check
									
									if (data.resourcelist == '') {
										//check
										$('#pagination').val('0');
										if ($("#no-more").length == 0) {
											// $('.postresult').append('<div id="no-more" class="text-center"><h3>You reached the end of the line!</h3><p>No more posts to load.</p></div>');
											
										}
										$('#no-more').show();
										$('.loadmore').hide();
									} else {
										if(data.totalpost <= 6){
											$('#pagination').val(0);
											$('.loadmore').hide();
											$('#no-more	').hide();
										}
										$('.loadmore').data('page', newPage);
										$('#loadmorecontent').append(data.resourcelist);									
										$('#loadmorecontent').show();
										var dd = parseInt(pagination) + 1;
										$('#pagination').val(dd);
										
									// $('.loadmorecontent').append(response);
									}
									blnloadData = false;
								}
								
							});
						}else{
							blnloadData = false;
						}
					}else{
						blnloadData = false;
					}
					
				}
				//ajax call
			
			//}
		});
	});